import { useState, useLayoutEffect } from "react"

export default (ref) => {
    const [width, setWidth] = useState(null)

    useLayoutEffect(() => {
        const { current } = ref
        setWidth(current.clientWidth)
        const handleResize = () => {
            setWidth(current.clientWidth);
        }
        current.addEventListener('resize', handleResize);
        return () => {
            current.removeEventListener('resize', handleResize);
        };
    }, [ref]);

    return width
}