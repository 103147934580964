import React from 'react';
import Icon from './Icon'
import Notice from './Notice'

const Styled = {
    DeleteArea: ({ children }) => {
        const style = {
            position: "absolute",
            opacity:"0.9",
            backgroundColor:"white",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            color: "gray",
            display: "flex",
            justifyContent: "center",
            zIndex:1,
            border: "3px dotted black"
        }
        return (
            <div style={style}>
                {children}
            </div>
        )
    }
}

const DeleteArea = () => {
    return (
        <Styled.DeleteArea>
                <Notice style={{margin:"auto 0"}}>Hierher ziehen zum Entfernen</Notice>
                <div id="delete-icon" style={{display:"flex", flexDirection:"column", margin:"4px"}}>
                    <Icon icon={"far fa-trash-alt"}> </Icon>
                </div>
        </Styled.DeleteArea>
    )
}

export default DeleteArea
