import React from 'react'
import PropTypes from 'prop-types'

const EmailLink = ({ to, subject, body, children }) => {
    const href = `mailto:${to}?subject=${subject}&body=${body}`
    return (
        <a href={href}> 
            { children ? children : to } 
        </a> 
    )
}
EmailLink.propTypes = {
    to: PropTypes.string.isRequired,
    subject: PropTypes.string,
    body: PropTypes.string
}

EmailLink.defaultProps = {
    to: "",
    subject: "",
    body: ""
}

export default EmailLink