import uuid from 'uuid'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
/**
 * Moves an item from one list to another list.
 */
const copy = (source, destination, droppableSource, droppableDestination) => {
  console.log("COPY:!!!")
    const destClone = Array.from(destination);
    const item = source[droppableSource.index];
  console.log(item, destClone)

    destClone.splice(droppableDestination.index, 0, { ...item, dndId: uuid() });
    return destClone;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

export let getClasses = (el) => el.getAttribute('class') ? el.getAttribute('class').split(/[\s\t\n]/g) : []
export let hasClass = (el,cn) => getClasses(el).findIndex(cn) === -1  
export let addClass = (el,cn) => el.setAttribute('class',[...getClasses(el), cn].join(' '))
export let removeClass = (el,cn) => el.setAttribute('class',getClasses(el).filter(x => x !== cn).join(' '))
export let toggleClass = (el,cn) => hasClass(el,cn) ? addClass(el,cn) : removeClass(el,cn)
export let prependBody = (el)  => document.body.insertBefore(el,document.body.children[0])


export { reorder, copy, move }

