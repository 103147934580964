import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import uuid from 'uuid'

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

const InquiryModal = ({ show, onClose }) => {
    const [ isSubmitting, setIsSubmitting ] = useState(false)
    const [ selectedFiles, setSelectedFiles ] = useState([])
    const [ checked, setChecked ] = useState(false)
    const fileInputRef = useRef(null)
    const fullState = useSelector(x=>x)

    const handleFileChange = (e) => {
        setSelectedFiles(e.target.files)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsSubmitting(true)

        const formData = new FormData(e.target)
        const uid = uuid()

        await fetch(`/api/saveState/${uid}`,{method:'POST', headers:{'Content-Type':'application/json'}, body: JSON.stringify(fullState)})
        formData.append('editorState',uid)

        fetch('/api/sendInquiry',{method:'POST',body: formData}).then(()=>{
            onClose()
          setIsSubmitting(false)
          setSelectedFiles([])
        })
    }

    return (
        <Modal
        show={show} 
        onHide={onClose}
        dialogClassName="modal-90w"
        size={"lg"}
    >
            <Modal.Header closeButton>
                <Modal.Title> Sie haben Fragen? </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{padding:"2rem"}}>
                <ul style={{paddingLeft:"16px"}}>
                    <li>Sie möchten eine kostenlose Überprüfung Ihrer Zaunplanung?</li>
                    <li>Sie wünschen individuelle Anpassungen (z.B. Sonderlängen)?</li>
                    <li>Sie benötigen eine professionelle technische Zeichnung?</li>
                </ul>
                <p> Wenn Sie dieses Formular absenden, erhalten unsere Experten einen Schnappschuss Ihrer Konfiguration. So können wir Ihnen ganz konkret bei der weiteren Planung behilflich sein. Sie können hier auch eigene Skizzen oder Fotos als Datei (bis 2MB als PDF oder Bild) hochladen.</p>
                <Form onSubmit={handleSubmit}>
                    <Form.Group >
                        <Form.Control name="information" as="textarea" rows="3" placeholder="Informationen oder besondere Wünsche (optional)"/>
                    </Form.Group>

                    <Form.Group>
                        <input style={{display:"none"}} name="files[]" type="file" accept=".png, .jpg, .pdf" ref={fileInputRef} onChange={handleFileChange} multiple />
                        <Button className="fileupload-button" onClick={() => fileInputRef.current.click()}>
                            <i style={{marginRight:"8px"}} className="fas fa-file-upload"></i>
                            Bilder/Skizzen Hochladen
                        </Button>
                        { selectedFiles.length === 0 && <span> Keine Datei ausgewählt</span>}
                        { selectedFiles.length === 1 && <span> {selectedFiles[0].name}</span>}
                        { selectedFiles.length > 1 && <span> {selectedFiles.length} Dateien ausgewählt</span>}
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" required name="name" />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>E-Mail</Form.Label>
                        <Form.Control type="email" required name="email"/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Check 
                        id="iwishaphonecall"
                        type="checkbox"
                        label="Wünschen Sie einen Rückruf?"
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                    />
                        </Form.Group>

                        { checked && 
                        <Form.Group>
                            <Form.Label> Telefonnummer </Form.Label>
                            <Form.Control type="text" required name="phoneNumber" />
                        </Form.Group>
                        }

                        <Button className={"hg-button"} type="submit" disabled={isSubmitting}>
                            {
                            isSubmitting ? 
                            <span><Spinner size="sm" animation="border" role="status"> </Spinner> Anfrage wird gesendet ...</span>
                            :
                            <span>
                                <i style={{marginRight:"8px"}} className="fas fa-paper-plane"></i>
                                Anfrage senden
                            </span>
                            }
                        </Button>

                    </Form>
                </Modal.Body>
            </Modal>
            )

}

export default InquiryModal
