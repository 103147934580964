import React, { useRef } from 'react'

import Layout from '../components/Layout'
import { useDrag, useDrop } from 'react-dnd'

const getHoverDirection = ({ ref, monitor }) => {
    if (ref.current === null || !monitor.isOver()) return null
    const hoverBoundingRect = ref.current.getBoundingClientRect()
    const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
    const clientOffset = monitor.getClientOffset()
    const hoverClientX = clientOffset.x - hoverBoundingRect.left

    if (hoverMiddleX > hoverClientX) {
        //left
        return "left"
    } else {
        //right
        return "right"
    }
}

const WorkspaceItem = ({ children, index, onDrop, item }) => {
    const ref = useRef(null)
    const [, dropRef] = useDrop({
        accept: ["NEW", "EXISTING"],
        collect: monitor => {
            const item = monitor.getItem()
            if (item === null || item.type === "EXISTING") return
            const hoverDirection = getHoverDirection({ ref, monitor })
            if (hoverDirection) {
                onDrop({
                    ...item,
                    destination: {
                        index: hoverDirection === "left" ? index : index + 1
                    }
                })
                item.type = "EXISTING"
                item.source = {
                    ...item.source,
                    index: hoverDirection === "left" ? index : index + 1,
                    list: "workspace",
                }
            }
        },
        drop: (item, monitor) => {
            console.log("drop: ", item)
        },
        hover: (item, monitor) => {
            if (item.type === "NEW") return
            const hoverDirection = getHoverDirection({ ref, monitor })

            const hoveredIndex = index
            const sourceIndex = monitor.getItem().source.index

            //don't insert on itself on itself
            if (hoveredIndex === sourceIndex || hoverDirection === null) {
                return
            }

            //disable left dropZone on right neighbour, as it would drop on itself
            if (hoveredIndex > sourceIndex && hoverDirection === "left") {
                return
            }

            //disable right dropZone on left neightbour, as it would drop on itself
            if (hoveredIndex < sourceIndex && hoverDirection === "right") {
                return
            }

            onDrop({
                ...item,
                destination: {
                    index: hoveredIndex
                }
            })
            item.source.index = hoveredIndex
        }
    })



    const [dragStyle, dragRef] = useDrag({
        item: {
            type: "EXISTING",
            source: { list: "workspace", index, image: {height:item.height, width:item.width, src:item.src} }
        },
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.5 : undefined,
        }),
    })

    if(!item.confirmed && dragStyle.opacity === undefined){
        dragStyle.opacity = 0.5
    }

    dragRef(dropRef(ref))
    const style = {
        ...dragStyle
    }

    return (
        <Layout flex style={style} onContextMenu={e => e.preventDefault()}>
            {children({ref})}
        </Layout>
    )
}

export default WorkspaceItem