import React from 'react'

const Length = ({ value, unit }) => (
    <span>{ transformValue(value, unit) } { transformUnit(unit) }</span>
)

function transformValue(value, unit) {
    switch(unit) {
        case "meter": return (parseInt(value)/100.0).toFixed(2)
        default:
            return (parseInt(value)/100.0).toFixed(2)
    }
}

function transformUnit(unit) {
    switch(unit){
        case "meter": return "m"
        default:
            return "m"
    }
}

export default Length