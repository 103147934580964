import React from 'react'
import PropTypes from 'prop-types'

const WidthIndicator = ({ value }) => {
  return (
      <div className="width-indicator-wrapper">
        <div className="width-indicator">
          {value}
        </div>
      </div>
  )
}

WidthIndicator.propTypes = {
  value: PropTypes.string.isRequired
}

export default WidthIndicator
