import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { workspaceItemsSelector } from '../reducers';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PriceList from '../components/PriceList';
import PriceSummary from '../components/PriceSummary';
import Settings from '../components/Settings';
import Button from 'react-bootstrap/Button'
import Icon from '../components/Icon'
import Spinner from 'react-bootstrap/Spinner'

import InquiryModal from '../components/InquiryModal'

const Divider = () => {
    return (
        <div style={{borderTop:"2px dotted lightgray"}}>

        </div>
        )
}

export default () => {
    const items = useSelector(workspaceItemsSelector);
    const state = useSelector(state => state)
    const [cartIsLoading, setCartIsLoading] = useState(false)

    const [ showInquiryModal, setShowInquiryModal ] = useState(false)

    const handleToCart = async () => {
        setCartIsLoading(true)
        const out = {
            items: state.workspace.items.map(({id}) => state.items[id]),
            posts: state.config.posts,
            caps: state.config.caps,
            anchors: state.config.anchors,
            extras: state.config.extras
        }
        let items = out.items
        let cart = Object.keys(items).reduce((res,key)=>({...res, [items[key].id]: res[items[key].id] ? res[items[key].id] + 1 : 1}),{})
        let doFillCart = Promise.all(Object.keys(cart).map(key => fetch(`https://www.holzundgarten.de/checkout/addArticle/sAdd/${key}/sQuantity/${cart[key]}`,{credentials:'include',method:'POST',refererPolicy:'no-referer'})))

        try {
            await doFillCart
            window.top.location = 'https://www.holzundgarten.de/checkout/cart'
        } catch(e) {
            console.log(e);
        }
        finally{
            setCartIsLoading(false)
        }


    }

    const handleToInquiry = () => {
        setShowInquiryModal(true)
    }

    const handleCloseInquiry = () => {
        setShowInquiryModal(false)
    }

    return (
        <div id="config">
            <div id="checkout">
                <Tabs
                defaultActiveKey="equipment"
                id="uncontrolled-tab-example">
                <Tab eventKey="equipment" title="Zubehör" style={{}}>
                    <Settings />
                </Tab>
                <Tab eventKey="checkout" title="Preise">
                    <PriceList items={items} />
                </Tab>
            </Tabs>
            <Divider> </Divider>
            <div style={{padding:"8px"}}>
                <PriceSummary items={items} />
                <div style={{ marginTop:"12px", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                    <Button onClick={handleToCart} className={"hg-button"} disabled={cartIsLoading}>
                        {
                        cartIsLoading ? 
                        <span><Spinner size="sm" animation="border" role="status"> </Spinner> Wird eingefügt ...</span>
                        : <span><Icon style={{paddingRight:"8px"}} icon={"cart-plus"} /> Warenkorb</span>
                        }
                    </Button>
                    <Button onClick={handleToInquiry} className={"hg-button"}>
                        <Icon style={{paddingRight:"8px"}} icon={"info"} />
                        Anfrage
                    </Button>
                    <InquiryModal show={showInquiryModal} onClose={handleCloseInquiry} />
                </div>
            </div>
        </div>
    </div>
    );
};


