import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ icon, style }) => {
  return <i style={{...style, margin:"auto 0px"}} className={`fas fa-${icon}`}></i>
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired
}

export default Icon
