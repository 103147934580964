import React from 'react';
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { useDrag, useDrop } from 'react-dnd'

import Item from '../components/Item'
import Layout from '../components/Layout'
import DeleteArea from '../components/DeleteArea'

import { itemsSelector } from '../reducers'

const Styled = {
    ItemWrapper: ({ children, forwardRef }) => {
        const style = {
            userSelect: "none",
            margin: "0 0.5rem",
            lineHeight: 1.5,
            borderRadius: "3px",
            padding: "4px",
        }
        //margin: "4px"
        return (
            <div style={style} ref={forwardRef}>
                { children }
            </div>
        )
    },
    Kiosk: ({ children, height, forwardRef, ...props }) => {
        const style = {
            height: `${height}px`,
            position: "relative",
            overflowX: "auto",
        }
        return (
            <Layout style={style} forwardRef={forwardRef} {...props}>
                { children }
            </Layout>
        )
    }
}


const ItemWrapper = ({ children, ...props }) => {
    const dispatch = useDispatch()
    const [ dragStyle , dragRef] = useDrag({
        item: { 
            type: "NEW",
            source: {list: "kiosk", id: props.id, image:{ height:props.height, width:props.width, src:props.src} }
        },
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.5 : 1,
        }),
        end: (item, monitor) => {
            if(item){
                dispatch({
                    type: "CONFIRM_ADD_ITEM",
                    index: item.source.index
                })
            }
        }
    })

    return (
        <Styled.ItemWrapper style={dragStyle} onContextMenu={e => e.preventDefault()}>
            <Item {...props} forwardRef={dragRef} baseHeight={props.baseHeight-20}/>
        </Styled.ItemWrapper>
    )
}



const Kiosk =({ height, onDelete }) => {
    const items = useSelector(itemsSelector)

    const [ { canDrop } , dropRef ] = useDrop({
        accept: "EXISTING",
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
        }),
        drop: item => {
            console.log("drop", item)
            onDelete(item)
        }
    })

    return (
        <Styled.Kiosk height={height} forwardRef={dropRef}>
            { canDrop && <DeleteArea />}
            { items.map(item =>(
                <ItemWrapper key={item.id} {...item} baseHeight={height*0.7} />
            ))}
        </Styled.Kiosk>
    )
}

Kiosk.propTypes = {
    height: PropTypes.number.isRequired
}

export default Kiosk
