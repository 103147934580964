import React from 'react';

import Currency from '../components/Currency'
import Length from '../components/Length'
import { useSelector } from 'react-redux';

const PriceSummary = ({ items }) => {
    const { posts, caps, anchors } = useSelector(state => state.config)
    let price = items.reduce((sum, cur) => sum + parseInt(cur.price), 0)
    let length = items.reduce((sum, cur) => sum + parseInt(cur.width), 0)

    if(posts && posts.price) {
        price += posts.price*items.length+1
        length += posts.width*items.length+1
    }
    if(caps && caps.price) {
        price += caps.price*items.length+1
    }
    if(anchors && anchors.price) {
        price += anchors.price*items.length+1
    }


    return (
        <div style={{ textAlign: "center" }} >
            <span>Gesamtlänge: <Length value={length} unit={"meter"} /></span>
            <h3><Currency value={price} /></h3>
            <span className="secondary-text">Inkl. 19% MwSt., zzgl. Frachtkosten</span>
        </div>
    )
}

export default PriceSummary