import React from 'react'
import { useDrop } from 'react-dnd'

import Notice from './Notice'

const placeholderStyle = {
    display: "flex",
    flex:1,
    justifyContent: "center"
}

const canDropPlaceholderStyle = {
    ...placeholderStyle,
    border: "3px dotted gray"

}

const isOverPlaceholderStyle = {
    ...canDropPlaceholderStyle
}

const Placeholder = ({ onDrop, accept, notice="Elemente hier ablegen", indicateCanDrop=true }) => {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept,
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        }),
        drop: source => {
            onDrop(source)
        }

    })

    const Content = indicateCanDrop ? <Notice> { notice } </Notice> : null


    if(isOver && indicateCanDrop) {
        return (
            <div style={isOverPlaceholderStyle} ref={drop}>
                { Content }
            </div>
        )
    }

    if(canDrop && indicateCanDrop) {
        return (
            <div style={canDropPlaceholderStyle} ref={drop}>
                { Content }
            </div>
        )
    }

    return (
        <div style={placeholderStyle} ref={drop}>
            { Content }
        </div>
    )
}

export default Placeholder
