import 'bootstrap/dist/css/bootstrap.min.css';
import { openModal } from './utils/modal'
import { render } from './utils/start'

window.fenceBuilder = {
    init: (settings, initialState) => render(document.getElementById('root'), settings, initialState),
    openModal: (settings, initialState) => {
        const modal = openModal()
        render(modal, settings, initialState)
    }
};


let config_name
let ids
const url_rex = /\/s\/(\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b)/
const m = url_rex.exec(window.location.pathname)
if(m) {
    fetch(`/api/loadState/${m[1]}`).then(res=>res.json()).then(state=>{
        const settings = state.settings;
        delete state.settings;
        window.fenceBuilder.init(settings,state)
    })
}
else {
    if(window.location.hash === "")
    {
        config_name = "default"
        ids = ["s121280"]
    }
    else
    {
        let s = window.location.hash.substring(1)
        let t = s.split('|')
        config_name = t[0]
        ids = t[1]
    }
    fetch(`/static/configs/${config_name}.json`).then(x=>x.json()).then(settings=>{
        fetch(`/api/getInfo/${ids}`).then(x=>x.json()).then(fence_data=>{
            const items = fence_data.map(fd=>({
                uid: fd['id'],
                id: fd['id'],
                description: fd['description'],
                src: `https://zaun.holzundgarten.de/api/getImage/${fd['id']}`,
                width: Number(fd['width']),
                price: Number(fd['price'])*100,
                height: {left: Number(fd['height']), right: Number(fd['height']), label: `${Number(fd['height'])}` }
            })).reduce((res,cur)=>({...res, [cur.id]:cur}),{})
            window.fenceBuilder.init(settings,{items})
        })
    });
}


