import React from 'react';

const Notice = ({ style, children }) => {
  const s = {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    padding: "0.5rem",
    margin: "0 0.5rem 0.5rem",
    border: "1px solid transparent",
    lineHeight: "1.5",
    color: "#aaa",
    fontWeight: "bold",
    ...style
  }

  return (
    <div style={s}>
      { children }
    </div>
  )
}

export default Notice