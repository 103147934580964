import React from 'react'
import PropTypes from 'prop-types'

const HeightIndicator = ({ value })  => (
  <div style={{fontSize:".625em"}}>
    <i className="fas fa-arrows-alt-v" style={{marginRight:"4px", color:"gray"}}></i>
    <span>
      { value }
    </span>
  </div>
)

HeightIndicator.propTypes = {
  value: PropTypes.string.isRequired
}

export default HeightIndicator
