import React from 'react'
import PropTypes from 'prop-types'

const Currency = ({ value, currency="€", precision=2, style={} }) => (
    <span style={style}> {(value/100.0).toFixed(precision)}{currency} </span>
)

Currency.propTypes = {
    value: PropTypes.number.isRequired,
    currency: PropTypes.string,
    precision: PropTypes.number
}

export default Currency

