const initialState = {
    items: []
}

export default function(state=initialState, action) {
    switch(action.type) {
        case "ADD_ITEM": return handleAddItem(state, action.payload)
        case "MOVE_ITEM": return handleMoveItem(state, action.payload)
        case "REMOVE_ITEM": return handleRemoveItem(state, action.payload)

        case "CONFIRM_ADD_ITEM": return handleConfirmAddItem(state)
        case "REVOKE_ADD_ITEM": return handleRevokeAddItem(state)
        default:
            return state
    }
}

const handleAddItem = (state, { id, uid, index }) => ({
    ...state,
    items: [
        ...state.items.slice(0, index),
        { id, uid, confirmed: false },
        ...state.items.slice(index)
    ]
})

const handleConfirmAddItem = state => ({
    ...state,
    items: state.items.map(item => ({ ...item, confirmed: true }))
})

const handleRevokeAddItem = state => ({
    ...state,
    items: state.items.filter(item => item.confirmed === true)
})

const handleMoveItem = (state, { source, destination }) => {
    if(source < destination) {
        return {
            ...state,
            items: [
                ...state.items.slice(0, source),
                ...state.items.slice(source+1, destination+1),
                state.items[source],
                ...state.items.slice(destination+1)
            ]
        }
    }else {
        return {
            ...state,
            items: [
                ...state.items.slice(0, destination),
                state.items[source],
                ...state.items.slice(destination, source),
                ...state.items.slice(source+1)
            ]
        }
    }
}

const handleRemoveItem = (state, { index }) => ({
    ...state,
    items: state.items.filter((item, idx) => idx !== index)
})