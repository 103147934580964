import { addClass, prependBody } from './util'

let overlay
let blurred_elements

export const openModal = () => {
  overlay = document.createElement('div')
  const modal = document.createElement('div')
  addClass(overlay, 'x-overlay')
  addClass(modal, 'x-modal')
  overlay.appendChild(modal)
  prependBody(overlay)
  //const shadow = modal.attachShadow({mode: 'closed'})
  blur(overlay)
  return modal
}

const blur = (overlay) =>
{
  blurred_elements = Array.from(document.querySelectorAll('body > *')).filter(el=>el !== overlay)
  blurred_elements.forEach((el)=>addClass(el,'x-blur'))
}

export const closeModal = () => document.body.removeChild(overlay)
