import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

const Settings = () => {

    const { posts, caps, anchors, extras } = useSelector(state => state.config)
    const dispatch = useDispatch()
    const settings = useSelector(state => state.settings)


    const handleChange =  (key, option) => {
        dispatch({
            type: "SET_CONFIG",
            payload: {
                key,
                value: option
            }
        })
    }

    const handleExtrasChange = (e) => {
        dispatch({
            type:"SET_CONFIG",
            payload: {
                key: "extras",
                value: e.target.checked
            }
        })
    }

    useEffect(() => {
        if(posts === undefined) {
            dispatch({
                type: "SET_CONFIG",
                payload: {
                    key: "posts",
                    value: settings.posts[0]
                }
            })
        }
    }, [settings.posts, posts, dispatch])

    useEffect(() => {
        if(!posts) return
        if(!caps || !posts.caps || !posts.caps.find(cap => cap.label === caps.label)) {
            dispatch({
                type: "SET_CONFIG",
                payload: {
                    key: "caps",
                    value: posts.caps && posts.caps[0]
                }
            })
        }
        if(!anchors || !posts.anchors || !posts.anchors.find(anchor => anchor.label === anchors.label)) {
            dispatch({
                type: "SET_CONFIG",
                payload: {
                    key: "anchors",
                    value: posts.anchors && posts.anchors[0]
                }
            })
        }
    }, [posts, settings.posts, dispatch, anchors, caps])
    return (
        <div>
            <h3>Pfosten</h3>
            <RadioBlock value={posts} onChange={handleChange} options={settings.posts} formGroupName={"posts"} />
            {posts && posts.caps && posts.caps.length > 0 && (
                <>
                    <h3>Kappen</h3>
                    <RadioBlock value={caps} onChange={handleChange} options={posts.caps} formGroupName={"caps"} /> 
                </>
            )}
            {posts && posts.anchors && posts.anchors.length > 0 && (
                <>
                    <h3>Bodenbefestiung</h3>
                    <RadioBlock value={anchors} onChange={handleChange} options={posts.anchors} formGroupName={"anchors"} />
                </>
            )}
            {settings.extras && (
                <>
                    <h3>Zubehör</h3>
                    <Form.Group style={{display:"flex"}}>
                        <Form.Check style={{display:"inline-block"}} id={"foobar"} checked={extras} onChange={handleExtrasChange}/>
                        <label htmlFor={"foobar"} className="clickable">{ settings.extras.label }</label>
                    </Form.Group>
                </>
            )}
        </div>
    )
}

const RadioBlock = ({options, formGroupName, onChange, value, ...props}) => (
    <Form.Group>
      <Col>
          {options.map(option => (
            <Form.Check
                checked={ ( value && option.label === value.label ) || false}
                onChange={e => onChange(formGroupName, option)}
                id={`radio-button-${formGroupName}-${option.label}`}
                key={option.label}
                type="radio"
                label={option.label}
                name={formGroupName}
                {...props}
            />
          ))}
      </Col>
    </Form.Group>
)

export default Settings
