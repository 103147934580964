import React from 'react';
import Editor from './containers/Editor'
import Config from './containers/Config'

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import './App.css'

const App = () => (
    <Container style={{ padding: "32px" }} fluid>
        <h2>Dichtzaun Classic</h2>
        <h5 style={{color:"gray"}}>Gestalten Sie hier Ihren Zaun</h5>
        <Row>
            <Col xs={12} xl={8} id="editor-wrapper">
                <Editor />
            </Col>
            <Col xs={12} xl={4}>
                <Config />
            </Col>
        </Row>
    </Container>
)

export default App