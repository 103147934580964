import App from '../App'
import createStore from '../store'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'

export let render = (domElement, settings=[], initialState) => ReactDOM.render(<Root initialState={initialState} settings={settings} />, domElement)

export const Root = ({settings, initialState}) => {
  const store = createStore({ ...initialState, settings })
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}
