import useWindowWidth from './useWindowWidth'

export default () => {
    const windowWidth = useWindowWidth()

    if(windowWidth >= 1200) return "xl"
    if(windowWidth >= 992) return "lg"
    if(windowWidth >= 768) return "md"
    if(windowWidth >= 576) return "sm"
    if(windowWidth < 576) return "xs"

    console.error("unhandled clientWIdth?!?", windowWidth)
    return "lg" //fallback


}