const initialState = {
    posts: undefined,
    caps: undefined,
    anchors: undefined,
    extras: false
}

export default function (state=initialState, action) {
    switch(action.type) {
        case "SET_CONFIG": return handleSetConfig(state, action.payload)
        default:
            return state
    }
}

const handleSetConfig = (state, { key, value }) => ({
    ...initialState,
    ...state,
    [key]: value
})
