import React from 'react';
import PropTypes from 'prop-types'

const Layout = ({ column, flex, children, style, forwardRef, ...props }) => {
    const s = {
        display: "flex",
        flexDirection: column ? "column" : "row",
        flex: flex ? 1 : "initial",
        ...style
    }
    return (
        <div style={s} ref={forwardRef} {...props}>
            { children }
        </div>
    )
}

Layout.propTypes = {
    column: PropTypes.bool,
    flex: PropTypes.bool
}

export default Layout