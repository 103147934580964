import React, { useState, useEffect } from 'react'

import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import EmailLink from './EmailLink'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'

import copyToClipboard from '../utils/clipboard'

const SaveModal = ({ show, onClose }) =>  {
    const [ copied, setCopied ] = useState(false)

    const fullState = useSelector(x => x)
    const { uid } = fullState 
    const [ url, setUrl ] = useState(`https://zaun.holzundgarten.de/s/${uid}`)

    useEffect(() => {
        //on dialog open, push current state to server
        (async () => {
            try {
                await fetch(`/api/saveState/${uid}`,{method:'POST', headers:{'Content-Type':'application/json'}, body: JSON.stringify(fullState)})
            }catch(e) {
                //TODO proper error handling
                console.error("save failed")
            }
        })()
    }, [])

    const handleCopyLink = () => {
        if(copyToClipboard(url)) {
            setCopied(true)
        }
    }


    return (
        <Modal
            show={show} 
            onHide={onClose}
            dialogClassName="modal-90w"
            size={"lg"}
        >
            <Modal.Header closeButton>
                <Modal.Title> Speichern & Teilen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                 <InputGroup className="mb-3">
                    <FormControl
                        placeholder=""
                        value={url}
                        readOnly
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                    />
                    <InputGroup.Append>
                        <Button className="hg-button" title="In die Zwischenablage kopieren" onClick={handleCopyLink}>
                            <i className="fas fa-copy"></i>
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
                { copied && <p style={{color:"#2c4b29", fontSize:"12px"}}>Der Link wurde in die Zwischenablage kopiert</p>}
                <p> 
                    <span>Kopieren Sie diesen Link, um Ihre Planung später fortzusetzen. 
                    Oder versenden Sie ihn via <EmailLink subject={"Mein Zaunplaner"} body={url} >E-Mail</EmailLink>.</span>
                    
                </p>
            </Modal.Body>

        </Modal>
    )
}

export default SaveModal
