import { combineReducers } from "redux";

import workspace from './workspace'
import items from './items'
import config from './config'
import settings from './settings'
import uid from './uid'

export default combineReducers({
    workspace,
    config,
    items,
    settings,
    uid
})

//TODO caching selector functions
export const workspaceItemsSelector =
    state => state.workspace.items.map(({ id, ...props }) => ({ ...state.items[id], ...props}))

export const itemsSelector =
    state => Object.values(state.items)

export const itemsByIdSelector = 
    state => state.items