import React from 'react';
import { useDispatch } from 'react-redux'
import { DndProvider } from 'react-dnd'
import uuid from 'uuid'

import MultiBackend, { Preview } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';

import Layout from '../components/Layout'
import Icon from '../components/Icon'

import Workspace from './Workspace'
import Kiosk from './Kiosk'
import useScreenSize from '../hooks/useScreenSize'

const GeneratePreview = ({itemType, item, style}) => {
  const height = Math.max(item.source.image.height.left, item.source.image.height.right)
  return (
    <img alt="" style={{height:"100px", width:`${(item.source.image.width/height)*100}`, opacity:"0.5", ...style}} src={item.source.image.src}/>
  )
};

const Styled = {
  Editor: ({ children, style, ...props}) => {
    const s = {
      useSelect: "none",
      ...style
    }
    return (
      <Layout style={s} {...props}>
        { children }
      </Layout>
    )
  }
}

const DragPossibleInstruction = () => (
  <div style={{padding:"5px", backgroundColor: "#333333", flex:"1", textAlign:"center", color:"#b4d5c8", pointerEvents:"none", userSelect:"none"}}>
    <Icon icon={"hand-pointer"} />
    <span style={{marginLeft:"4px"}}> Ziehen Sie die gewünschten Elemente in den oberen Bereich </span>
  </div>
)

const Editor = () => {
  const dispatch = useDispatch()
  const screenSize = useScreenSize()

  const minimapHeight = {
    xs: 0,
    sm: 80,
    md: 80,
    lg: 80,
    xl: 80
  }[screenSize]

  const contentHeight = {
    xs: 270,
    sm: 470,
    md: 470,
    lg: 470,
    xl: 470
  }[screenSize]
  
  const kioskHeight = {
    xs: 100,
    sm: 160,
    md: 160,
    lg: 160,
    xl: 160
  }[screenSize]

  const handleDragEnd = ({ type, source, destination }) => {
    switch(type) {
      case "NEW": dispatch({
        type: "ADD_ITEM",
        payload: {
          uid: uuid(),
          id: source.id,
          index: destination.index
        }
      })
      break
      case "EXISTING": dispatch({
        type: "MOVE_ITEM",
        payload: {
          source: source.index,
          destination: destination.index
        }
      })
      break
      default:
        console.warn("dragtype: ", type, " not implemented")
    }
  }

  const handleDelete = ({ type, source, destination }) => {
    console.log("handleDelete: ", type, source, destination)
    dispatch({
      type: "REMOVE_ITEM",
      payload: {
        index: source.index
      }
    })
  }

  return (
      <Styled.Editor column>
            <DndProvider backend={MultiBackend} options={HTML5toTouch}>
              <Workspace contentHeight={contentHeight} onDrop={handleDragEnd} minimapHeight={minimapHeight} />
              <DragPossibleInstruction />
              <Kiosk height={kioskHeight} onDelete={handleDelete} />
              <Preview>{GeneratePreview}</Preview>
          </DndProvider>
      </Styled.Editor>
   ) 
}

export default Editor
