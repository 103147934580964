import React from 'react';
import Currency from '../components/Currency'
import { useSelector } from 'react-redux';

const PriceList = ({ items }) => {
    let transformed = transform(items)
    const { posts, caps, anchors } = useSelector(state => state.config)

    if(posts && posts.price) {
        transformed = [
            ...transformed,
            {
                uid: "posts",
                description: posts.description || posts.label,
                price: posts.price,
                count: items.length + 1
            }
        ]
    }

    if(caps && caps.price) {
        transformed = [
            ...transformed,
            {
                uid: "caps",
                description: caps.description || caps.label,
                price: caps.price,
                count: items.length +1
            }
        ]
    }

    if(anchors && anchors.price) {
        transformed = [
            ...transformed,
            {
                uid: "anchors",
                description: anchors.description || anchors.label,
                price: anchors.price,
                count: items.length +1
            }
        ]
    }

    return (
        <div style={{ display: "flex" }}>
            <table style={{ fontSize: "12px", flexGrow: "1" }} className={"price-table"}>
                <tbody>
                    <tr>
                        <th>Beschreibung</th>
                        <th>Anzahl</th>
                        <th>Einzelpreis</th>
                        <th>Preis</th>
                    </tr>
                    {transformed.map((item, idx) => {
                        return (
                            <tr key={item.uid}>
                                <td> {item.description}</td>
                                <td> {item.count}x</td>
                                <td> <Currency value={item.price} /></td>
                                <td> <Currency style={{fontWeight:"bold"}} value={item.price * item.count} /></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}


function transform(items) {
    return Object.values(items.reduce((res, cur) => {
        if (res[cur.id]) {
            res[cur.id].count++
        } else {
            res[cur.id] = { ...cur, count: 1 }
        }
        return res
    }, {}))
}

export default PriceList