import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'

import WidthIndicator from './WidthIndicator'
import HeightIndicator from './HeightIndicator'

const Item = ({ id, lite, forwardRef, dragStyle, spacing, baseHeight }) => {
    const [imageSize, setImageSize] = useState(null)
    const items = useSelector(state => state.items)
    const item = items[id]
    const { src, height, width } = item

    /*useEffect(() => {
        const maxHeight = Object.values(items).reduce((max, cur) => Math.max(max, Math.max(cur.height.left, cur.height.right)), 0)
        const minHeight = Object.values(items).reduce((max, cur) => Math.min(max, Math.min(cur.height.left, cur.height.right)), maxHeight)
        const ih = Math.round(Math.max(item.height.left, item.height.right)/(minHeight/45))
        const iw = width*(ih/100)
        setImageHeight(ih)
        setImageWidth(iw)
    }, [])*/

    useEffect(() => {
        const maxHeight = Object.values(items).reduce((max, cur) => Math.max(max, Math.max(cur.height.left, cur.height.right)), 0)

        setImageSize({
            height: (baseHeight/maxHeight)*Math.max(height.left, height.right),
            width: (baseHeight/maxHeight)*width
        })
    }, [baseHeight, height.left, height.right, items, width])

    if(!imageSize) return null

    const spacingStyle = {
        marginRight:`${spacing*4}px`
    }

    let draggableStyle = {}
    if(forwardRef) {
        draggableStyle.cursor = "grab"
    }

    return (
        <div style={{height:"100%", maxWidth:"100%", display:"flex", flexDirection:"column", justifyContent:"space-between", ...dragStyle, ...spacingStyle}}>
            { !lite &&
                <div style={{pointerEvents:"none"}}>
                    <WidthIndicator value={width.toString()}/>
                </div>
            }

            <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-end", height:lite ? "100%" : "calc(100% - 32px)", ...draggableStyle}}>
                <div ref={forwardRef} style={{display:"flex", flexDirection:"column", justifyContent:"flex-end", height:`${imageSize.height}px`, width:`${imageSize.width}px`}}>
                    <img  alt="" style={{height:`100%`, pointerEvents:"none"}} src={src} />
                </div>
                { !lite && <HeightIndicator value={height.label}/> }
            </div>
        </div>
    )

}

Item.propTypes = {
    id: PropTypes.string.isRequired,
    spacing: PropTypes.number,
    liet: PropTypes.bool
}

Item.defaultProps = {
    spacing: 0,
    lite: false,
    baseHeight: 200
}

export default Item