import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'

const Styled = {
  Minimap: ({height, children}) => {
    const style = {
      height: `height:${height}px`,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      position: "relative",
      opacity: "0.6",
      overflow: "hidden",
      padding: "8px 0px 8px 8px"
    }
    return (
      <div style={style}>
      { children }
      </div>
    )
  },
  Window: ({ position, width, children, ...props }) => {
    const style = {
      position: "absolute",
      left: `${position}px`,
      width: `${width}px`,
      border: "2px solid #2c4b29",
      opacity: "0.3",
      top: 0,
      bottom: 0,
      zIndex: 2,
      cursor: "pointer"
    }
    return (
      <div style={style} {...props}>
      { children }
      </div>
    )
  },
  Blur: ({ left, right, children, ...props }) => {
    const style = {
      position: "absolute",
      left: `${left}px`,
      right: `${right}px`,
      height: "100%",
      backgroundColor: "lightgray",
      opacity: "0.4",
      top: "0px",
      cursor: "pointer"
    }
    return (
      <div style={style} {...props}>
      { children }
      </div>
    )
  }
}

const Minimap = (props) => {
  const {  height, children, scrollLeft, originalWidth, onMove, baseHeight, originalBaseHeight } = props
  const [ position, setPosition ] = useState(0)
  const [ scale, setScale ] = useState(baseHeight/originalBaseHeight)

  useEffect(() => {
    const pos = Math.ceil(scrollLeft * scale)
    setPosition(pos)
  }, [scale, scrollLeft])

  useEffect(() => {
    setScale(baseHeight/originalBaseHeight)
  }, [originalBaseHeight, baseHeight])

  const handleMove = (pos) => {
    onMove(Math.round(pos/scale))
  }

  return (
    <Styled.Minimap height={height}>
    <WindowOverlay onMove={handleMove} position={position} width={Math.ceil(originalWidth*scale)} clientWidth={originalWidth}></WindowOverlay>
    <div style={{height: `${baseHeight}px`, display:"flex"}}>
    { children }
    </div>
    </Styled.Minimap>
  )
}

const WindowOverlay = ({ position, width, clientWidth, onMove }) => {
  let [isMoving, setMoving] = useState(false)

  const handleMousedown = (e) => {
    e.preventDefault()
    const nextPosition = Math.max(e.clientX - width/2, 0)
    onMove(nextPosition)
    setMoving(true);
  }

  const handleMouseUp = (e) => {
    setMoving(false);
  }

  const handleMouseMove = (e) => {
    if (isMoving)
    {
      const nextPosition = Math.max(e.clientX - width/2, 0)
      onMove(nextPosition)
    }
  }

  return (
    <div onMouseLeave={handleMouseUp} onMouseMove={handleMouseMove} onMouseDown={handleMousedown} onMouseUp={handleMouseUp}>
    <Styled.Blur name="left" left={0} right={clientWidth-position}></Styled.Blur>
    <Styled.Window name="window" width={width+2} position={position} />
    <Styled.Blur name="right" left={position + width} right={0}></Styled.Blur>
    </div>
  )
}

Minimap.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  originalBaseHeight: PropTypes.number.isRequired,
  baseHeight: PropTypes.number.isRequired,
  originalWidth: PropTypes.number.isRequired,
  scrollLeft: PropTypes.number.isRequired,
  clientWidth: PropTypes.number.isRequired,
  scrollWidth: PropTypes.number.isRequired
}

Minimap.defaultProps = {
  height: 100
}

export default Minimap
