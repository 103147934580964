import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useElementWidth from '../hooks/useElementWidth'
import { workspaceItemsSelector } from '../reducers'
import FlipMove from 'react-flip-move';
import Button from 'react-bootstrap/Button'

import WorkspaceItem from './WorkspaceItem'
import SaveModal from '../components/SaveModal'

import Item from '../components/Item'
import Layout from '../components/Layout'
import Placeholder from '../components/Placeholder'
import Minimap from '../components/Minimap'

const Post = () => {
    const { posts } = useSelector(state => state.config)
    if (posts === undefined || posts.imageUrl === undefined) {
        return null
    }

    return (
        <img src={posts.imageUrl} />
    )
}

const Styled = {
    Workspace: ({ children, height, ...props }) => {
        const style = {
            height: `${height}px`,
            display: "flex",
        }
        return (
            <Layout style={style} column {...props}>
                {children}
            </Layout>
        )
    },
    Content: ({ children, height, forwardRef, ...props }) => {
        const style = {
            //backgroundImage: "linear-gradient(45deg, #ffffff 25%, #fafafa 25%, #fafafa 50%, #ffffff 50%, #ffffff 75%, #fafafa 75%, #fafafa 100%)",
            overflowX: "scroll",
            height: `${height}px`,
            padding: "0rem 2rem 1rem",
            backgroundColor: "#f3f1f1",
            flex: "initial",
            //background: "url(\"data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3MCIgaGVpZ2h0PSI3MCI+CjxyZWN0IHdpZHRoPSI3MCIgaGVpZ2h0PSI3MCIgZmlsbD0iI2JiZDgxNyI+PC9yZWN0Pgo8ZyB0cmFuc2Zvcm09InJvdGF0ZSg0NSkiPgo8cmVjdCB3aWR0aD0iOTkiIGhlaWdodD0iMjUiIGZpbGw9IiNhOWNlMDAiPjwvcmVjdD4KPHJlY3QgeT0iLTUwIiB3aWR0aD0iOTkiIGhlaWdodD0iMjUiIGZpbGw9IiNhOWNlMDAiPjwvcmVjdD4KPC9nPgo8L3N2Zz4=\")"

        }
        return (
            <Layout style={style} forwardRef={forwardRef} {...props}>
                {children}
            </Layout>
        )
    },
    Item: ({ children }) => {
        const style = {
            useSelect: "none",
            lineHeight: 1.5,
            display: "flex"
        }
        return (
            <div style={style}>
                {children}
            </div>
        )
    }
}



const Workspace = ({ onDrop, contentHeight, minimapHeight }) => {
    const items = useSelector(workspaceItemsSelector)
    const contentRef = useRef(null)
    const contentWidth = useElementWidth(contentRef)
    const workspaceItemHeight = contentHeight * 0.4
    const minimapItemHeight = 40
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [ isSaveModalMounted, setIsSaveModalMounted ] = useState(false)

    const [scrollInfo, setScrollInfo] = useState({
        scrollLeft: 0,
        clientWidth: 0,
        scrollWidth: 0
    })

    const handlePlaceholderDrop = ({ type, source }) => {
        onDrop({
            type,
            source,
            destination: {
                list: "workspace",
                index: items.length
            }
        })
    }

    const handleMinimapScroll = (pos) => {
        contentRef.current.scrollLeft = pos
        handleScroll({ target: contentRef.current })
    }

    const handleScroll = (e) => {
        const { scrollLeft, clientWidth, scrollWidth } = e.target
        setScrollInfo({
            scrollLeft,
            clientWidth,
            scrollWidth
        })
    }

    const handleOpenSaveModal = () => {
        setIsSaveModalMounted(true)
        setShowSaveModal(true)
    }

    const handleCloseSaveModal = () => {
        setShowSaveModal(false)
        setTimeout(() => {
            setIsSaveModalMounted(false)
        }, 500)
    }

    //initial scrollstatus setup
    useEffect(() => {
        handleScroll({ target: contentRef.current })
    }, [])

    return (

        <Styled.Workspace >
            <div style={{ margin: "40px 0px" }}>
                {(contentWidth && minimapHeight > 0) &&
                    <Minimap {...scrollInfo} height={minimapHeight} onMove={handleMinimapScroll} baseHeight={minimapItemHeight} originalBaseHeight={workspaceItemHeight} originalWidth={contentWidth}>
                        {items.map((item, idx) => (
                            <Item key={`minimap-${item.uid}`} lite {...item} baseHeight={minimapItemHeight} />
                        ))}
                    </Minimap>
                }
            </div>
            <div style={{ position: "relative" }}>
                <Styled.Content flex onScroll={handleScroll} forwardRef={contentRef} height={contentHeight}>
                    <Button className="hg-button save-button" onClick={handleOpenSaveModal}>
                        <i style={{ marginRight: "8px" }} className="fas fa-save"></i>
                    Speichern
                </Button>
                    <Post />
                    {/*<FlipMove style={{display: "flex"}}> */}
                    <div style={{ display: "flex" }}>

                        {items.map((item, idx) => (
                            <React.Fragment key={"workspace-" + item.uid}>
                                <WorkspaceItem index={idx} onDrop={onDrop} item={item}>
                                    {({ ref }) => (
                                        <Item forwardRef={ref} {...item} baseHeight={workspaceItemHeight} />
                                    )}
                                </WorkspaceItem>
                                <Post />
                            </React.Fragment>
                        ))}
                    </div>
                    {/*</FlipMove>*/}
                    <Placeholder indicateCanDrop={items.length === 0} onDrop={handlePlaceholderDrop} accept={"NEW"} />
                </Styled.Content>
            </div>
            { isSaveModalMounted && <SaveModal show={showSaveModal} onClose={handleCloseSaveModal} /> }
        </Styled.Workspace>
    )
}

Workspace.propTypes = {
    contentHeight: PropTypes.number.isRequired,
    minimapHeight: PropTypes.number.isRequired
}

export default Workspace
